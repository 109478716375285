var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ck-file-input", {
    attrs: {
      value: _vm.synonyms,
      id: "synonyms",
      label: "Upload new thesaurus",
      accept: "text/csv",
      error: _vm.errors.get("synonyms")
    },
    on: {
      input: function($event) {
        return _vm.onInput("synonyms", $event === null ? null : $event.content)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }