var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _c("vue-headful", {
        attrs: { title: _vm.appName + " - Edit Thesaurus" }
      }),
      _c(
        "gov-back-link",
        { attrs: { to: { name: "admin-index-search-engine" } } },
        [_vm._v("Back to thesaurus")]
      ),
      _c(
        "gov-main-wrapper",
        [
          _c(
            "gov-grid-row",
            [
              _c(
                "gov-grid-column",
                { attrs: { width: "two-thirds" } },
                [
                  _c("gov-heading", { attrs: { size: "xl" } }, [
                    _vm._v("Thesaurus")
                  ]),
                  _c("gov-warning-text", [
                    _vm._v(
                      "\n          Please note, uploading a new Thesaurus may cause the search\n          functionality to stop working for a short period of time. Please do\n          not replace during peak hours.\n        "
                    )
                  ]),
                  _c("gov-heading", { attrs: { size: "m" } }, [
                    _vm._v("Edit Thesaurus")
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "Update the synonyms used when searching for services."
                    )
                  ]),
                  _c("gov-body", [
                    _vm._v(
                      "\n          As all rows in a CSV must contain the same number of columns, the\n          system will strip out any blank cells. See the example below for\n          reference:\n        "
                    )
                  ]),
                  _c("ck-code", [
                    _vm._v("\n          run,dash,escape,elope,flee,"),
                    _c("br"),
                    _vm._v(
                      "\n          help,aid,assist,support,encourage,back"
                    ),
                    _c("br"),
                    _vm._v("\n          people,persons,,,\n        ")
                  ]),
                  _c("gov-heading", { attrs: { size: "s" } }, [
                    _vm._v("Guidance on adding thesaurus entries:")
                  ]),
                  _c("gov-list", { attrs: { bullet: "" } }, [
                    _c("li", [
                      _vm._v(
                        "Synonyms should, if at all possible, be only singular words"
                      )
                    ]),
                    _c(
                      "li",
                      [
                        _vm._v(
                          "\n            If you must use a multiple word synonym, it must appear on a row\n            with only one other synonym. This other synonym can then appear\n            later in the sheet with further synonyms. Example:\n\n            "
                        ),
                        _c(
                          "gov-table",
                          [
                            _c(
                              "gov-table-row",
                              { attrs: { slot: "header" }, slot: "header" },
                              [
                                _c("gov-table-header"),
                                _c(
                                  "gov-table-header",
                                  { attrs: { center: "" } },
                                  [_vm._v("A")]
                                ),
                                _c(
                                  "gov-table-header",
                                  { attrs: { center: "" } },
                                  [_vm._v("B")]
                                ),
                                _c(
                                  "gov-table-header",
                                  { attrs: { center: "" } },
                                  [_vm._v("C")]
                                ),
                                _c(
                                  "gov-table-header",
                                  { attrs: { center: "" } },
                                  [_vm._v("D")]
                                )
                              ],
                              1
                            ),
                            _c(
                              "template",
                              { slot: "body" },
                              [
                                _c(
                                  "gov-table-row",
                                  [
                                    _c(
                                      "gov-table-header",
                                      { attrs: { center: "" } },
                                      [_vm._v("1")]
                                    ),
                                    _c("gov-table-cell", [
                                      _vm._v("old people")
                                    ]),
                                    _c("gov-table-cell", [_vm._v("elderly")]),
                                    _c("gov-table-cell"),
                                    _c("gov-table-cell")
                                  ],
                                  1
                                ),
                                _c(
                                  "gov-table-row",
                                  [
                                    _c(
                                      "gov-table-header",
                                      { attrs: { center: "" } },
                                      [_vm._v("2")]
                                    ),
                                    _c("gov-table-cell", [_vm._v("elderly")]),
                                    _c("gov-table-cell", [_vm._v("old")]),
                                    _c("gov-table-cell", [_vm._v("elders")]),
                                    _c("gov-table-cell", [_vm._v("pensioner")])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c("li", [
                      _vm._v(
                        "\n            The multi-word synonym must appear in column A, and it’s\n            replacement in column B\n          "
                      )
                    ])
                  ]),
                  _c("thesaurus-form", {
                    attrs: { errors: _vm.form.$errors, synonyms: _vm.file },
                    on: {
                      "update:synonyms": function($event) {
                        _vm.file = $event
                      },
                      clear: function($event) {
                        return _vm.form.$errors.clear($event)
                      }
                    }
                  }),
                  _c("gov-section-break", { attrs: { size: "l" } }),
                  _vm.form.$submitting
                    ? _c(
                        "gov-button",
                        { attrs: { disabled: "", type: "submit" } },
                        [_vm._v("Updating...")]
                      )
                    : _c(
                        "gov-button",
                        {
                          attrs: { type: "submit" },
                          on: { click: _vm.onSubmit }
                        },
                        [_vm._v("Update")]
                      ),
                  _vm.form.$errors.any() ? _c("ck-submit-error") : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }